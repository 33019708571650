import { mapGetters, mapMutations, mapState } from 'vuex';
import KnFinancialSummary from '../components/KnFinancialSummary/KnFinancialSummary.vue';
import KnGeneralSummary from '../components/KnGeneralSummary/KnGeneralSummary.vue';

export default {
  components: { KnFinancialSummary, KnGeneralSummary },
  mixins: [],
  data() {
    return {
      currentTab: 1,
    };
  },
  async created() {
    this.setIsLogin(false);
  },
  computed: {
    ...mapState(['institutionId']),
    ...mapGetters(['nameUserLoggedIn']),
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    setCurrentTab(value) {
      this.currentTab = value;
    },
  },
};
