import { postItem } from '../../../api/api-methods';
import { api } from '../../../api/axios-base';
import { numberFormatMX } from '../../shared/helpers/dataUtils';

export const fetchTotalIncomeByStudent = async (
  studentId,
  firstDate,
  endDate,
  institutionId
) => {
  try {
    const response = await api.post(
      '/app-administracion/helpers/get-ingreso-por-alumno',
      {
        id_alumno: studentId,
        fecha_inicio: firstDate, //this.dateRange.from,
        fecha_fin: endDate, //this.dateRange.to,
        id_institucion_educativa: institutionId, //this.institutionId
      }
    );
    const responseData = await response.data;
    return responseData.resultado.total_ingreso__sum;
  } catch (error) {
    console.error('Error al obtener total de ingreso al mes por alumno', error);
  }
};
export const fetchTotalExpenseByEmployee = async (
  employeeId,
  fistDate,
  endDate,
  institutionId
) => {
  try {
    const response = await api.post(
      `/app-administracion/helpers/get-egresos-empleado`,
      {
        id_empleado: employeeId,
        fecha_inicio: fistDate,
        fecha_fin: endDate,
        id_institucion_educativa: institutionId,
      }
    );
    const responseData = await response.data;
    return responseData.resultado.total_egreso__sum;
  } catch (error) {
    console.error(
      'Error al obtener total de egreso al mes por empleado',
      error
    );
  }
};
export const fetchTotalExpenseBySupplier = async (
  supplierId,
  fistDate,
  endDate,
  institutionId
) => {
  try {
    const response = await api.post(
      '/app-administracion/helpers/get-egresos-proveedor',
      {
        id_proveedor: supplierId,
        fecha_inicio: fistDate, //this.dateRange.from,
        fecha_fin: endDate, //this.dateRange.to,
        id_institucion_educativa: institutionId, //this.institutionId,
      }
    );
    const responseData = await response.data;
    return responseData.resultado.total_egreso__sum;
  } catch (error) {
    console.error(
      'Error al obtener total de egreso al mes por empleado',
      error
    );
  }
};
export const fetchTotalIncomeByMonth = async (
  { firstDay, lastDay },
  institutionId
) => {
  try {
    const response = await api.post(
      '/app-administracion/helpers/get-resumen-ingresos-mes',
      {
        fecha_inicio: firstDay,
        fecha_fin: lastDay,
        id_institucion_educativa: institutionId, //this.institutionId,
      }
    );
    const responseData = await response.data;
    return responseData.resultado.total_ingreso__sum;
  } catch (error) {
    console.error('Error al obtener total de ingreso del mes', error);
  }
};
export const fetchTotalExpenseByMonth = async (
  { firstDay, lastDay },
  institutionId
) => {
  try {
    const response = await api.post(
      '/app-administracion/helpers/get-resumen-egresos-mes',
      {
        fecha_inicio: firstDay,
        fecha_fin: lastDay,
        id_institucion_educativa: institutionId, //this.institutionId,
      }
    );
    const responseData = await response.data;
    return responseData.resultado.total_egreso__sum;
  } catch (error) {
    console.error('Error al obtener total de egreso del mes', error);
  }
};
export const fetchStatusReport = async (
  startdate,
  firstDate,
  institutionId
) => {
  try {
    const response = await api.post(
      '/app-administracion/helpers/get-estado-resultados-mes',
      {
        fecha_inicio: startdate, //this.dateRange.from,
        fecha_fin: firstDate, //this.dateRange.to,
        id_institucion_educativa: institutionId, ///this.institutionId,
      }
    );
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error('Error al obtener estado de resultados', error);
  }
};
export const fetchStudentByEI = async (institutionId) => {
  try {
    const response = await api.get(
      `/app-personas/filters/alumno?institucion_educativa=${institutionId}`
    );
    const responseData = await response.data;
    if (response.status === 200) return responseData;
  } catch (error) {
    console.error(
      'Error al intentar obtener categorias de egresos por institucion educativa',
      error
    );
  }
};
export const postGetBirthdaysByDate = async (
  data = {
    fecha_inicio: null,
    fecha_fin: null,
    id_institucion_educativa: null,
  }
) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/obtener-cumpleanieros-por-fecha',
      data
    );
    const { e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los cumpleañeros. ' + e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener cumpleañeros. ' + error,
    };
  }
};

export const postGetTotalStudentsPerLevel = async (
  data = {
    id_ciclo_escolar: null,
  }
) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/obtener-total-alumnos-por-nivel-educativo',
      data
    );
    const { e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los alumnos por nivel. ' + e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener alumnos por nivel. ' + error,
    };
  }
};

/** Métodos para las gráficas */

export const generateRandomColor = () => {
  const randomValue = getRandomHexNumber(16777215);
  const randomValueLength = randomValue.length;
  const spaces = 6 - randomValueLength;
  let valuesForComplete = '';
  for (let i = 0; i < spaces; i++) {
    const value = getRandomHexNumber(15);
    valuesForComplete += value;
  }
  return `#${randomValue}${valuesForComplete}b2`;
};

export const getRandomHexNumber = (limit) => {
  return Math.floor(Math.random() * limit).toString(16);
};

export const configChart = (
  canvasId,
  chartKey,
  chartType,
  chartTitle,
  chartSubtitle,
  labels,
  dataset,
  total,
  prefixBigMount = '$',
  chartColsMin = 1,
  chartColsMax = 12,
  cardMdMin = 4,
  cardMdMax = 6,
  cardColsMax = 12
) => {
  const chart = {
    id: canvasId,
    title: chartTitle,
    subtitle: chartSubtitle,
    keyGraph: chartKey,
    colsGraph: total > 0.0 ? chartColsMax : chartColsMin,
    showText: true,
    showGraph: total > 0.0,
    bigMount: `${prefixBigMount}${numberFormatMX.format(total)}`,
    chartData: {
      type: chartType,
      data: {
        labels: [...labels],
        datasets: [{ ...dataset }],
      },
    },
    cols: cardColsMax,
    md: total > 0.0 ? cardMdMax : cardMdMin,
  };
  return chart;
};

export const getLabelsArray = (array, labelKey) => {
  return array.map((item) => item[labelKey]);
};

export const getTotalsArray = (array, fatherLabelKey, childLabelKey = null) => {
  return array.map((item) =>
    childLabelKey !== null
      ? parseFloat(item[fatherLabelKey][childLabelKey])
      : parseFloat(item[fatherLabelKey])
  );
};

export const getTotalAmount = (array) => {
  return array.reduce((a, b) => a + b, 0);
};

export const getColorArray = (arrayLength) => {
  const colorArray = [];
  for (let i = 0; i < arrayLength; i++) {
    colorArray.push(generateRandomColor());
  }
  return colorArray;
};

export const statusReportChart = (
  array,
  labelKey,
  containerAmountKey,
  sumAmountKey,
  canvasId,
  chartKey,
  chartType,
  chartTitle,
  chartSubtitle,
  prefixBigMount = '$',
  chartColsMin = 1,
  chartColsMax = 12,
  cardMdMin = 4,
  cardMdMax = 6,
  cardColsMax = 12
) => {
  const labels = getLabelsArray(array, labelKey);
  const totals = getTotalsArray(array, containerAmountKey, sumAmountKey);
  const total = getTotalAmount(totals);
  const colors = getColorArray(labels.length);
  // console.log('colors de ', labelKey, colors);

  const dataset = {
    label: chartTitle,
    data: [...totals],
    backgroundColor: [...colors],
  };
  const chartObj = configChart(
    canvasId,
    chartKey,
    chartType,
    chartTitle,
    chartSubtitle,
    labels,
    dataset,
    total,
    prefixBigMount,
    chartColsMin,
    chartColsMax,
    cardMdMin,
    cardMdMax,
    cardColsMax
  );
  return chartObj;
};
