import { mapState } from 'vuex';
import KnAnnouncementCard from '../KnAnnouncementCard/KnAnnouncementCard.vue';
import {
  postGetBirthdaysByDate,
  postGetTotalStudentsPerLevel,
} from '../../helpers/dashboardOptions';
import { mapGetters } from 'vuex/dist/vuex.common.js';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  months,
} from '../../../shared/helpers/dateUtils';
import { Chart as ChartJS, registerables } from 'chart.js';
import { fetchAnnouncements } from '../../../employees/helpers/announcementOptions';
import { fetchSchoolCycles } from '../../../configuration/helpers/KnGroupsOptions';

ChartJS.register(...registerables);

export default {
  name: 'KnGeneralSummary',
  components: { KnAnnouncementCard },
  mixins: [],
  props: {},
  data() {
    return {
      loading: false,
      announcementLoading: false,
      employeesBirthdays: [],
      currentMiniYear: '24',
      dateFrom: null,
      dateTo: null,
      currentMonthName: '',
      studentsByLevel: [],
      announcements: [],
      /** Variables para la gráfica de niveles educativos */
      labels: [],
      values: [],
      datasets: [],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(75, 192, 192)',
        'rgb(255, 205, 86)',
        'rgb(54, 162, 235)',
        'rgb(201, 203, 207)',
      ],
      config: {
        type: 'polarArea',
        data: null,
        options: {
          responsive: true,
        },
      },
      chartCtx: null,
      loadedChart: false,
      currentChart: null,
      selectedSchoolYear: null,
      schoolYears: [],
    };
  },
  computed: {
    ...mapState(['institutionId']),
    ...mapGetters(['currentSchoolYear', 'allSchoolYears']),
  },
  watch: {
    institutionId: {
      async handler() {
        this.selectedSchoolYear = null;
        await this.loadData();
        await this.initChart();
      },
    },
  },
  async created() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    this.currentMonthName = this.getMonthText(currentMonth);
    this.dateFrom = getFirstDayOfMonth(currentYear, currentMonth);
    this.dateTo = getLastDayOfMonth(currentYear, currentMonth);

    this.currentMiniYear = currentYear.toString().slice(2, 4);
    if (this.currentSchoolYear) {
      this.selectedSchoolYear = this.currentSchoolYear;
      this.schoolYears = this.allSchoolYears;
    }
    await this.loadData();
    // this.buildStudentsPerLevelChartData();
  },
  mounted() {
    // console.log('chart config', this.config);
    this.chartCtx = document.getElementById('studentsPerLevel');
    this.initChart();
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.getEmployeeBirthdays();
      await this.getPaginatedAnnouncements();
      this.loading = false;
    },
    getMonthText(value) {
      return months[value];
    },
    async getEmployeeBirthdays() {
      const { ok, data, message } = await postGetBirthdaysByDate({
        fecha_inicio: this.dateFrom,
        fecha_fin: this.dateTo,
        id_institucion_educativa: this.institutionId,
      });
      if (ok) {
        this.employeesBirthdays = data;
      } else {
        this.employeesBirthdays = [];
        console.log('No se pudo obtener los cumpleaños. ' + message);
      }
    },
    async getTotalStudentsPerLevel() {
      const { ok, data, message } = await postGetTotalStudentsPerLevel({
        id_ciclo_escolar: this.selectedSchoolYear
          ? this.selectedSchoolYear.id
          : null,
      });
      if (ok) {
        this.studentsByLevel = data;
      } else {
        this.studentsByLevel = [];
        console.log('No se pudo obtener alumnos por nivel. ' + message);
      }
    },
    async getPaginatedAnnouncements() {
      try {
        this.announcementLoading = true;
        const { ok, data, message } = await fetchAnnouncements({
          institutionId: this.institutionId,
          systemStatus: true,
          startDate: this.dateFrom,
          limit: 30,
        });
        if (ok) {
          this.announcements = data;
        } else {
          console.log('Error al obtener anuncios. ', message);
          this.announcements = [];
        }
      } catch (error) {
        console.log('Error al intentar obtener anuncios');
      } finally {
        this.announcementLoading = false;
      }
    },
    async getSchoolYears() {
      try {
        const { ok, data, message } = await fetchSchoolCycles({
          institutionId: this.institutionId,
          systemStatus: true,
          dato: this.currentMiniYear,
        });
        if (ok) {
          this.schoolYears = data;
          this.selectedSchoolYear = data.length ? data[0] : null;
        } else {
          console.log('No se pudieron obtener los ciclos escolares', message);
        }
      } catch (error) {
        console.error('Error al intentar obtener ciclo escolares', error);
      }
    },
    buildStudentsPerLevelChartData() {
      this.labels = [];
      this.values = [];
      this.datasets = [];

      this.labels = this.studentsByLevel.map(
        (sbl) => sbl.nombre_nivel_educativo
      );
      this.values = this.studentsByLevel.map((sbl) => sbl.total_alumnos);
      this.datasets.push({
        label: 'Alumnos por nivel educativo',
        data: this.values,
        backgroundColor: this.backgroundColor.slice(0, this.values.length),
        borderWidth: 1,
      });
      this.config.data = {
        labels: this.labels,
        datasets: this.datasets,
      };
    },
    async initChart() {
      // console.log('Dentro de initChart', this.loading);
      this.loadedChart = false;

      if (!this.selectedSchoolYear) {
        await this.getSchoolYears();
      }

      await this.getTotalStudentsPerLevel();
      this.buildStudentsPerLevelChartData();
      if (this.currentChart) {
        this.currentChart.destroy();
      }
      this.currentChart = new ChartJS(this.chartCtx, this.config);
      this.loadedChart = true;
    },
  },
};
